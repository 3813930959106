/**
 * apiCallback methods
 */
export const apiMethods = {
    get: 'GET' as const,
    post: 'POST' as const,
    put: 'PUT' as const,
    delete: 'DELETE' as const,
    patch: 'PATCH' as const,
};

/**
 * The languages that this application is able to support
 */
export const appLanguages = {
    english: 'en' as const,
    french: 'fr' as const,
}


export const UploadStates = {
    error: 'error' as const,
    pending: 'pending' as const,
    done: 'done' as const,
}

export const WarrantyAndReturnMessageTypes = {
    text: 1,
    image: 2,
    forwardedMessage: 3,
    time: 4, // time should always be the biggest number since the sorting algorithm depends on this condition
}

export const ReadingFileAs = {
    binaryString: 'binaryString',
    dataUrl: 'dataUrl',
    text: 'text',
    arrayBuffer: 'arrayBuffer',
}

export const ChatMessageOperationTypes = {
    add: 'add' as const,
    remove: 'remove' as const,
}

/**
 * The application Setting keys used to get or set specific portions of the application token
 */
export const ApplicationSettingKeys = {
    language: 'language' as const,
}

/**
 * Different types of file types the file-fetcher of browser accepts
 */
export const InputFileAcceptTypes = {
    png: '.png',
    jpg: '.jpg',
    jpeg: '.jpeg',
    mp4: '.mp4',
    mov: '.mov',
    webm: '.webm',
    flv: '.flv',
    wmv: '.wmv',
    pdf: '.pdf',
}

export const InputFileAcceptMimes = {
    images: 'image/*',
    videos: 'videos/*',
    audios: 'audio/*',
}
