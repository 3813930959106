import React, {useEffect, useState} from "react";
import classnames from "classnames";

const Switch = ({onChange: onChangeProp, name, className = '', value: valueProp = false}) => {
    const [value, setValue] = useState(valueProp);

    /**
     * Listens to the changes in value prop and with each change:
     * sets the inner state of the value to be in sync.
     */
    useEffect(() => {
        if (valueProp !== value) {
            setValue(valueProp)
        }
    }, [valueProp])

    /**
     * Toggles the value of the switch and in case that onChange callback exists, calls it with the new value.
     */
    const onToggle = () => {
        const newValue = !value;
        setValue(newValue);
        if (onChangeProp) {
            onChangeProp(newValue)
        }
    }

    return (
        <>
            <div className={classnames('switch', className)}>
                <input
                    type="checkbox"
                    checked={value}
                    onChange={(e) => false}
                />
                <i onClick={onToggle}/>
            </div>
        </>
    )
}

export default Switch;
