import {useTranslation} from "react-i18next";
import {Col, Row} from "reactstrap";
import {ReactComponent as PartIcon} from "../../../../../assets/images/chat-screen/next.svg";
import moment from "moment";
import React from "react";

const ChatTableLg = ({data, openPartInformationTab, loading}) => {
    const {t} = useTranslation();

    return (
        <>
            <Row className={'chat-table-header'}>
                <Col xs={2}>
                    <p>
                        {t('warranty-and-return-chat.table.rga')}
                    </p>
                </Col>
                <Col xs={2}>
                    <p>
                        {t('warranty-and-return-chat.table.type')}
                    </p>
                </Col>
                <Col xs={2}>
                    <p>
                        {t('warranty-and-return-chat.table.part')}
                    </p>
                </Col>
                <Col xs={2}>
                    <p>
                        {
                            !data?.serialNo
                                ? t('warranty-and-return-chat.table.count')
                                : t('warranty-and-return-chat.table.serialNo')
                        }
                    </p>
                </Col>
                <Col xs={2}>
                    <p>
                        {t('warranty-and-return-chat.table.operatorName')}
                    </p>
                </Col>
                <Col xs={2}>
                    <p>
                        {t('warranty-and-return-chat.table.requestDate')}
                    </p>
                </Col>
            </Row>
            <div className={'chat-table-divider'}/>
            <Row className={'chat-table-row'}>
                <Col xs={2}>
                    {
                        loading
                            ? <Col>
                                <div className={'loading-div chat-table-value'}>
                                    <div/>
                                </div>
                            </Col>
                            : <p>
                                {data?.rga ?? '--'}
                            </p>
                    }
                </Col>
                <Col xs={2}>
                    {
                        loading
                            ? <Col>
                                <div className={'loading-div chat-table-value'}>
                                    <div/>
                                </div>
                            </Col>
                            : <p>
                                {data?.type ? `${data.type} ${t('warranty-and-return-chat.table.requestTypeExtension')}` : '--'}
                            </p>
                    }
                </Col>
                <Col xs={2}>
                    {
                        loading
                            ? <Col>
                                <div className={'loading-div chat-table-value'}>
                                    <div/>
                                </div>
                            </Col>
                            : data?.partNo && data.partShareLinkAddress
                                ? (
                                    <div className={'part-button'}
                                         onClick={openPartInformationTab}>
                                        <p>
                                            {data.partNo}
                                            <PartIcon className={'ml-1'}/>
                                        </p>
                                    </div>
                                )
                                : '--'
                    }
                </Col>
                <Col xs={2}>
                    {
                        loading
                            ? <Col>
                                <div className={'loading-div chat-table-value'}>
                                    <div/>
                                </div>
                            </Col>
                            : <p>
                                {data?.serialNo ?? data?.unSeriliazablePartsCount ?? '--'}
                            </p>
                    }

                </Col>
                <Col xs={2}>
                    {
                        loading
                            ? <Col>
                                <div className={'loading-div chat-table-value'}>
                                    <div/>
                                </div>
                            </Col>
                            : <p>
                                {!data?.operator ? '--' : (data?.operator.firstName ?? '') + (data?.operator.lastName ?? '')}
                            </p>
                    }
                </Col>
                <Col xs={2}>
                    {
                        loading
                            ? <Col>
                                <div className={'loading-div chat-table-value'}>
                                    <div/>
                                </div>
                            </Col>
                            : <p>
                                {data?.submitDateTime ? moment(data.submitDateTime).format('MM-DD-YYYY') : '--'}
                            </p>
                    }

                </Col>
            </Row>
        </>
    );
}

export default ChatTableLg;
