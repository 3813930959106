import React, {useLayoutEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {IconButton} from "../../base/button";
import {Settings as SettingsIcon} from '@material-ui/icons'
import {Popover} from "@material-ui/core";
import {Col, Container, Row} from "reactstrap";
import Switch from "../../base/switch";
import CacheService from "../../../../core/services/cache_service";
import {ApplicationSettingKeys} from "../../../../core/constants/enums";

const Settings = () => {
    const {t, i18n} = useTranslation();
    const [popover, setPopover] = useState();
    const [settings, setSettings] = useState(() => CacheService.getSettings());
    const [prevSettings, setPrevSettings] = useState(() => CacheService.getSettings());

    /**
     * Listens to the changes of settings and with each change:
     *
     * * if language has changed, then sets the language of i18n based on the changed language.
     */
    useLayoutEffect(() => {
        const languageKey = ApplicationSettingKeys.language;
        if (settings[languageKey] !== prevSettings[languageKey]) {
            CacheService.setSettings(languageKey, settings[languageKey]);
            i18n.changeLanguage(settings.language).then();
            setPrevSettings(prevState => ({...prevState, [languageKey]: settings.language}));
        }
    }, [settings])


    /**
     * Changes the language of the application between french and english
     * @param {boolean} useFrench
     */
    const changeLanguage = (useFrench) => {
        if (useFrench && settings.language !== 'fr') {
            setSettings(prevState => ({...prevState, language: 'fr'}))
        }
        if (!useFrench && settings.language !== 'en') {
            setSettings(prevState => ({...prevState, language: 'en'}))
        }
    }

    return (
        <>
            <div className={'settings'}>
                <IconButton
                    onClick={(e) => setPopover(e.currentTarget)}
                >
                    <SettingsIcon/>
                </IconButton>
                <Popover
                    id={!!popover ? 'settings' : undefined}
                    elevation={2}
                    classes={{
                        paper: 'settings-popover'
                    }}
                    open={!!popover}
                    onClose={() => setPopover(null)}
                    anchorReference={'anchorEl'}
                    anchorEl={popover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Container>
                        <Row>
                            <Col xs={12} className={'mb-4'}>
                                <p className={'title'}>{t('settings.title')}</p>
                            </Col>
                            <Col xs={12} className={'d-flex align-items-center justify-content-between'}>
                                <p className={'entry'}>{t('settings.frenchLanguage')}</p>
                                <Switch
                                    value={settings.language === 'fr'}
                                    onChange={changeLanguage}
                                />
                            </Col>
                        </Row>
                    </Container>

                </Popover>
            </div>

        </>
    )
}

export default Settings;
