import React, {useRef, useState} from "react";
import {readFile} from "../../../../core/services/utils";
import useIsMounted from "../../../hooks/use_is_mounted";
import {InputFileAcceptMimes, InputFileAcceptTypes, ReadingFileAs} from "../../../../core/constants/enums";
import accepts from "attr-accept";
import {UploadButton} from "../button";
import classnames from "classnames";


const DropZone = ({
                      error = false,
                      disabled,
                      accept = Object.values(InputFileAcceptTypes),
                      id,
                      onSuccess,
                      multiple = false,
                      children,
                      setTempUrl,
                      loading,
                      setLoading,
                      className,
                      identifier = '',
                      openOnClick = false,
                      uploading,
                      dropZoneClassName,
                      labelClassName,
                      noParse = false,
                  }) => {
    const [highlight, setHighlight] = useState(false);
    const inputRef = useRef();
    const isMounted = useIsMounted();

    /**
     * Opens the file dialog from the system.
     */
    const openFileDialog = () => {
        if (disabled || loading) return;
        inputRef?.current?.click()
    }

    /**
     * Highlights the dropzone if dragged over it.
     * @param e {DragEvent}
     */
    const onDragOver = (e) => {
        e.stopPropagation();
        if (disabled || loading) return;
        if (!highlight) setHighlight(true);
    }

    /**
     * Un-Highlights the dropzone if leaves the draggable area it.
     * @param e {DragEvent}
     */
    const onDragLeave = (e) => {
        e.stopPropagation();
        if (highlight) setHighlight(false);
    }

    /**
     * Gets the dropped files and then un-highlights the drop-zone and calls onFilesSelected with the returned array
     * from fileListToArray function.
     * @param e {DragEvent}
     */
    const onDrop = (e) => {
        e.preventDefault();
        if (disabled || loading || !e.dataTransfer?.files?.length) return;
        const files = fileListToArray(e.dataTransfer.files);
        onDragLeave(e);
        onFilesSelected(files).then();
    }


    /**
     * For every selected file, if the file has the accepted types, returns the files array.
     * if no multiple, then only returns the first selected file.
     *
     * @param files {FileList}
     * @return {FileList}
     */
    const fileListToArray = (files) => {
        const dataTransfer = new DataTransfer();
        for (let index = 0; index < files.length; index++) {
            if (!accepts(files.item(index), accept)) continue;
            if (multiple) {
                dataTransfer.items.add(files.item(index));
            } else {
                dataTransfer.items.add(files.item(index));
                break;
            }
        }
        return dataTransfer.files;
    }

    /**
     * For each of the files, converts them to their base 64 string and then sends the converted array of
     * potentially filled as the argument of onSuccess callback.
     * @param files {FileList}
     */
    const onFilesSelected = async (files) => {
        if (disabled || loading || files?.length <= 0) return;
        setLoading(true);
        if (noParse) {
            setLoading(false);
            onSuccess(files);
            return;
        }
        const res = [];
        for (let index = 0; index < files.length; index++) {
            if (!accepts(files.item(index), InputFileAcceptMimes.images)) {
                const text = await readFile(files.item(index), ReadingFileAs.text);
                if (!isMounted()) return;
                res.push(text);
            } else {
                const dataUrl = await readFile(files.item(index), ReadingFileAs.dataUrl);
                if (!isMounted()) return;
                if (setTempUrl) setTempUrl(dataUrl);
                res.push(dataUrl.split(',')[1]);
            }
            if (!multiple) break;
        }
        setLoading(false);
        onSuccess(res?.filter(e => e?.length));
    }


    return (
        <div
            className={classnames('drop-zone', {
                'high-light': highlight,
                'error': error,
                'cursor-progress-hover': uploading,
                'cursor-not-allowed-hover': disabled || loading,
                'cursor-pointer-hover': !(disabled || loading),
                [dropZoneClassName]: dropZoneClassName?.length,
                [identifier]: identifier?.length,
            })}
            onDragEnter={onDragOver}
            onDragLeave={onDragLeave}
            onDragOver={e => e.preventDefault()}
            onDrop={onDrop}
            onClick={() => openOnClick && openFileDialog()}
        >
            {
                openOnClick
                    ? (
                        <UploadButton
                            labelClassName={labelClassName}
                            disabled={disabled || loading}
                            accept={accept}
                            ref={inputRef}
                            multiple={multiple}
                            id={`upload-button-${id}`}
                            onFileSelect={onFilesSelected}
                            className={className}
                        >
                            {
                                typeof children === 'function'
                                    ? children(openFileDialog)
                                    : children
                            }
                        </UploadButton>
                    )
                    : typeof children === 'function'
                        ? children(openFileDialog)
                        : children
            }

        </div>
    )
}


export default DropZone;
